import React from 'react'

function Footer(props) {
  return (
    <div className='footer-container'>
        <a href="/">LC Website Solutions</a>
        
    </div>
  )
}


export default Footer
