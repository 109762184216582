import React from 'react'
import Setup from '../components/documentation/Setup'

const Documentation = () => {
  return (
    <div className='Documentation-View'>
      <h1>React Documentation and Libraries</h1>
      <Setup></Setup>
        
    </div>
  )
}


export default Documentation