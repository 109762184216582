import React from 'react'
import Footer from './Footer'

function Review(props) {
  return (
    <div>

        <iframe title='ReviewForm' width="1640px" height="980px" src="https://forms.office.com/Pages/ResponsePage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAN__lqWrRdUNlg3QTJYWlhDOTU1VjdRVjJRSkxSMkxSVS4u&embed=true" frameborder="0" marginwidth="0" marginheight="0" style={{border: "none", maxWidth: "100%", maxHeight:"120vh"}} allowfullscreen webkitallowfullscreen mozallowfullscreen msallowfullscreen> </iframe>

        <Footer></Footer>


        
    </div>
  )
}


export default Review
